<template>
  <div>
    <step-header/>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <div class="step-content">
        <loading :loading="loading" />
        <div v-if="!loading" class="container">
          <div class="row justify-content-center zoom">
            <div class="col-8">
              <div class="form-group mb-34">
                <div class="form-group mb-34">
                  <validation-provider
                    name="Industry"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for="#">
                     {{$t("crt-request.step2.label")}}
                      <span class="required">*</span>
                    </label>
                    <multi-select
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      name="Industry"
                      id="industry"
                      @close="closeSelect"
                      :openDirection="'bottom'"
                      :searchable="false"
                      v-model="form.industry"
                      :options="industrys"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </div>
                      </template>
                    </multi-select>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </div>
              <validation-provider
                name="Product Name"
                :rules="{ itemName: true }"
                v-slot="validationContext"
              >
                <div class="form-group mb-34">
                  <label for>
                    Product Name
                    <span class="required">*</span>
                  </label>
                  <input spellcheck="true"
                    v-model="form.name"
                    :class="{
                          'is-invalid':
                            (
                                !getValidationState(validationContext) 
                                && 
                                validationContext.errors[0]
                            )
                        }"
                    type="text"
                    class="form-control"
                    placeholder="Type in the name of the product you are looking for"
                  />
                  <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </div>
              </validation-provider>
              <validation-provider
                name="Product ID"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <div class="form-group mb-34">
                  <label for>
                    Product ID
                    <span class="required">*</span>
                  </label>
                  <input spellcheck="true"
                    v-model="form.id"
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                    type="text"
                    class="form-control"
                    placeholder="Type in the name of the product you are looking for"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </div>
              </validation-provider>
              <div class="form-group mb-34">
                <validation-provider
                  name="units"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label for="#">
                    Unit
                    <span class="required">*</span>
                  </label>
                  <multi-select
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                    name="units"
                    id="units"
                    @close="closeSelect"
                    :openDirection="'bottom'"
                    :searchable="false"
                    v-model="form.units"
                    :options="units"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </div>
                    </template>
                  </multi-select>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>

              <div class="form-group mb-34 d-flex justify-content-between">
                <div class="w-50 mr-4">
                  <validation-provider
                    name="Stock"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>Stock</label>
                    <input spellcheck="true"
                      v-model="form.stock"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      type="number"
                      class="form-control"
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>

                <div class="w-50 ml-4">
                  <validation-provider
                    name="Currency"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>
                      Currency
                      <span class="required">*</span>
                    </label>
                    <select
                      @change="setInputCurrency"
                      v-model="form.currency"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      class="form-control w-100"
                    >
                      <option hidden :value="null" selected>Select</option>
                      <option
                        v-for="(item, index) in currencies"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group mb-34 d-flex justify-content-between">
                <div class="w-50 mr-4">
                  <validation-provider
                    name="Minimum Price"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>
                      Min. Price
                      <span class="required">*</span>
                    </label>
                    <currency-input
                      :currency="inputCurrency"
                      v-model="form.price.min"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      class="form-control"
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <div class="w-50 ml-4">
                  <validation-provider
                    name="Maximum Price"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>
                      Max.Price
                      <span class="required">*</span>
                    </label>
                    <currency-input
                      :currency="inputCurrency"
                      v-model="form.price.max"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      class="form-control"
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </div>

              <validation-provider
                name="Product Description"
                :rules="{ itemDescription: true }"
                v-slot="validationContext"
              >
                <div class="form-group mb-34">
                  <label for>:Product Description:</label>
                  <textarea spellcheck="true"
                    v-model="form.desc"
                    :class="{
                      'is-invalid':
                        (
                            !getValidationState(validationContext) 
                            &&
                            validationContext.errors[0]
                        )
                    }"
                    type="text"
                    rows="7"
                    class="form-control"
                  ></textarea>
                   <div class="counter">
                  <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                  <div class="number-back-counter">
                    {{ counter }} / 1200
                  </div>
                  </div>
                </div>
              </validation-provider>
              <label for>
                Product İmages
                <span class="required">*</span>
              </label>
              <div class="dropzone-content mb-34">
                <vue-dropzone
                  @vdropzone-removed-file="deleteImage"
                  @vdropzone-files-added="fileUpload"
                  @vdropzone-thumbnail="getThumbnail"
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drag and dropjjj products images here
                    </h3>
                    <small class="text-muted d-block">OR</small>
                    <button @click.prevent class="btn btn-primary">
                      Add Images
                    </button>
                    <p>jpg,png 150 kb maximum</p>
                  </div>
                </vue-dropzone>
                <div
                  class="img-preview-content mt-3 d-flex justify-content-between"
                >
                  <div v-for="(b, i) in 5" :key="i" class="item">
                    <div
                      @click.prevent="deleteThumbnail(i, productImages[i].type)"
                      v-if="productImages[i]"
                      class="clearBtn"
                    >
                      X
                    </div>
                    <img
                      :src="!productImages[i] ? noImage : productImages[i].src"
                      alt
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-group d-flex align-items-center justify-content-between"
              >
                <router-link
                  :to="'/register-steps/step-3/products'"
                  class="d-block mr-2"
                >
                  Cancel
                </router-link>
                <button
                  @click="handleSubmit(formSubmit)"
                  class="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <step-footer
        v-if="!loading && 1 === 2"
        :isBack="false"
        :backStep="'step-2'"
      />
    </validation-observer>
  </div>
</template>

<script>
import StepFooter from "../../components/register-step/step-footer";
import baseImage from "../../assets/img/product-add-no-image.png";
import getUnit from "@/graphql/public/getUnit.gql";
import getCurrencies from "@/graphql/public/getCurrencies.gql";
import getIndustry from "@/graphql/public/getIndustry.gql";
import updateProduct from "@/graphql/me/updateProduct.gql";
import getProducts from "@/graphql/me/getProducts.gql";
import getProduct from "@/graphql/me/getProduct.gql";
import { mapGetters, mapMutations } from "vuex";
import Loading from "@/components/global/loading";
import StepHeader from '@/components/register-step/step-header.vue';
import _ from 'lodash';
export default {
  components: {
    StepFooter,
    Loading,
    StepHeader
  },
  data() {
    return {
      noImage: baseImage,
      productId: null,
      inputCurrency: null,
      form: {
        images: [],
        currency: null,
        price: {},
        industry: {},
        units: {},
        deletedImg: [],
        desc: ''
      },
      loading: false,
      currencies: [],
      productImages: [],
      industrys: [],
      units: [],
      dropzoneOptions: {
        createImageThumbnails: true,
        addRemoveLinks: true,
        autoProcessQueue: false,
        url: "https://httpbin.org/post",
        thumbnailWidth: null,
        thumbnailHeight: null,
        acceptedFiles: "image/*",
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      }
    };
  },
  computed: {
    counter() {
        return _.size(this.form.desc);
    }
  },
  methods: {
    ...mapMutations({
      setUserList: "inquiries/setUserList",
    }),
    async formSubmit() {
      try {
        this.loading = true;
        let payload = {
          id: parseInt(this.productId),
          product_id: this.form.id,
          industry_id: parseInt(this.form.industry.id),
          name: this.form.name,
          unit_id: parseInt(this.form.units.id),
          currency_id: parseInt(this.form.currency),
          min_price: this.form.price.min,
          max_price: this.form.price.max,
          stock: parseInt(this.form.stock),
          description: this.form.desc,
          moq: 1,
          images: this.form.images,
          delete_images: this.form.deletedImg,
        };
        let data = await this.$apollo.mutate(updateProduct, {
          product: payload,
        });
        await this.getProducts();
        await this.$router.push("/register-steps/step-3/products");
      } catch (e) {
        let splited = e.message.split(".");
        let err = splited.pop();
        let errType = splited.pop();
        let msg = this.$t(`${errType}.${err}`);
        let par = {
          title: this.$t("general.error"),
          message: msg,
          type: "danger",
          hide: true,
        };
        this.makeToast(par);
        this.scrollToTop();
        console.log(e.message.split);
      } finally {
        this.loading = false;
      }
    },
    async getProducts() {
      let params = {
        limit: 10,
        filters: {},
      };
      let data = await this.$apollo.query(getProducts, params);
      let response = data("**.list");
      console.log(response);
      this.setUserList(response);
      return response;
    },
    async getUnits() {
      try {
        let data = await this.$apollo.query(getUnit);
        let response = data("**.units");
        this.units = response;
        //return this.$router.push("/register-steps/step-1");
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        console.log(msg);
      } finally {
      }
    },
    async getIndustry() {
      try {
        let par = {
          type: "PRODUCT_AND_INQUIRY",
        };
        let data = await this.$apollo.query(getIndustry, par);
        let response = data("**.industries");
        this.industrys = response;
        console.log("industry", data);
        //return this.$router.push("/register-steps/step-1");
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        console.log(msg);
      } finally {
      }
    },
    databaseImgDelete(index) {
      let image = this.productImages[index].src;
      let code = image.replace(this.CDN_IMAGE_PREFIX, "");

      this.form.deletedImg.push(code);
    },
    deleteThumbnail(index, type) {
      if (type === "added") {
        this.databaseImgDelete(index);
      }
      if (type === "new") {
        console.log("Yeni Eklenen Silindi");
      }
      this.productImages.splice(index, 1);
      this.productImages = [...this.productImages];
    },
    deleteImage($event) {
      console.log($event);
    },
    getThumbnail(file, dataUrl, $event) {
      if (this.productImages.length === 5) {
        return;
      }
      this.form.images.push(file);
      this.productImages.push({
        src: dataUrl,
        type: "new",
      });
    },
    errorsUpload(file, dataUrl) {
      console.log(dataUrl);
    },
    fileUpload(file) {
      if (this.productImages.length === 5) {
        this.$bvToast.toast(this.$t("Toaster.img-add-up"), {
          title: this.$t("Toaster.error-title"),
          autoHideDelay: 1350,
        });
        return false;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid;
      }
      return null;
    },
    setInputCurrency() {
      let handler = this.currencies.find(
        (item) => item.id === this.form.currency
      );
      this.inputCurrency = handler.code;
    },
    closeSelect(value, id) {
      console.log(id);
      if (id == "industry") {
        if (!this.form.industry) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            Industry: [msg],
          });
        }
      }
      if (id == "years") {
        if (!this.form.years) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            years: [msg],
          });
        }
      }
      if (id == "positions") {
        if (!this.form.position) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            Positions: [msg],
          });
        }
      }
    },
    setSelectIndustry(id) {
      let handler = this.industrys.find((item) => item.id === id);
      console.log(id);
      this.form.industry = handler;
    },
    setSelectUnit(id) {
      let handler = this.units.find((item) => item.id === id);
      this.form.units = handler;
    },
    setSelectCurrency(id) {
      let handler = this.currencies.find((item) => item.id === id);
      this.form.currency = handler.id;
    },
    async getCurrencies() {
      let data = await this.$apollo.query(getCurrencies);
      let response = data("**.currencies");
      this.currencies = response;
    },
    async getItem(id) {
      let data = await this.$apollo.query(getProduct, { id: id });
      let response = data("**.get");
      this.form.id = response.product_id;
      this.setSelectIndustry(response.industry.id);
      this.setSelectUnit(response.unit.id);
      this.setSelectCurrency(response.currency.id);
      let x = response.images;
      for (let i in x) {
        this.productImages.push({
          src: `${this.CDN_IMAGE_PREFIX}${x[i]}`,
          type: "added",
        });
      }

      this.form.desc = response.contents.find(
        (item) => item.default === true
      ).description;
      this.form.price.min = response.min_price;
      this.form.price.max = response.max_price;
      this.form.stock = response.stock;
      this.form.name = response.contents.find(
        (item) => item.default === true
      ).name;
    },
  },
  async created() {
    this.loading = true;
    this.productId = this.$route.params.id;
    await this.getIndustry();
    await this.getUnits();
    await this.getCurrencies();
    await this.getItem(parseInt(this.productId));
    this.setInputCurrency();
    this.loading = false;
  },
};
</script>

<style></style>
